import React from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import WebinarHeader from './cmp/WebinarHeader';
import WebinarBanner from './cmp/WebinarBanner';
import WebinarFlayer from './cmp/WebinarFlayer';


const Webinar = () => {
    return (
        <div>
            <Header/>
            <div>

            <WebinarHeader/>
            <WebinarBanner/>
            <WebinarFlayer/>
            </div>
         
            <Footer/>
        </div>
    );
};

export default Webinar;