import React from "react";

const WebinarFlayer = () => {
  return (
    <div className="webinar-flyer">
      <div className="webinar-flyer-post">
        <img src="assets/webinars/images/webinar flyer.jpeg" alt="poster" />
      </div>

      <div className="webinar-flyer-content">
        <div className="webinar-flyer-content-title">Meet Our Experts</div>
        <div className="webinar-flyer-content-subtitle">
          “Discover the power of AI-driven predictive analysis to transform
          network efficiency and reliability.”
        </div>

        <div className="webinar-flyer-content-resource">
          <div className="webinar-flyer-content-resource-1">
            <img src="assets/webinars/images/circle-person.png"/>
            <div className="webinar-flyer-content-resource-1-title">
            <p>
            Dr. Ajith Pasqual <br/> <span>CEO, Paraqum Technologies</span>
            </p>
            </div>
            
          </div>

          <div className="webinar-flyer-content-resource-2">
            <img src="assets/webinars/images/circle-person2.png" />
            <div className="webinar-flyer-content-resource-1-title">
            <p>
            Chris Johnson <br/> <span>Title of the 2nd RP</span>
            
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarFlayer;
