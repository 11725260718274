import React from "react";

const WebinarBanner = () => {
  return (
    <div className="webinar-banner">
      <div class="gradient-overlay gradient-top"></div>
      <div class="gradient-overlay gradient-bottom"></div>

      <div class="webinar-banner-content">
        <div className="webinar-banner-content-live">
            <img src="assets/webinars/icons/live.png" alt="live" />
            <p>LIVE</p>
        </div>
        <div className="webinar-banner-content-title">Standard QOE 
        <br/> vs <b>Paraqum Wi-Di</b> </div>
        <div className="webinar-banner-content-subtitle">Discover the power of AI-driven predictive analysis to transform network efficiency and reliability.</div>
        <div className="webinar-banner-content-hr"></div>
        <div className="webinar-banner-content-datetime">
        <div className="webinar-banner-content-date">THURSDAY, <b>27<sup>TH</sup> FEBRUARY 2025</b></div>
        <div className="webinar-banner-content-date">FROM -<b> 1:00PM - 2:00PM CST</b></div>
        </div>
        <div className="webinar-banner-content-hr"></div>
        <button className="webinar-banner-content-register">Register Now</button>
      </div>
    </div>
  );
};

export default WebinarBanner;