import React from "react";

const WebinarHeader = () => {
  return (
    <>
      <div className="webinar-header">
        <div className="webinar-header-live">
          <img src="assets/webinars/icons/live.png" alt="live" />
          <p>LIVE</p>
        </div>
        <p className="webinar-header-title">Paraqum <b>Webinars</b></p>
        <p className="webinar-header-subtitle">Join our experts as they discuss the latest trends in network intelligence and QoE</p>
      </div>
    </>
  );
};

export default WebinarHeader;
